import './about-page.scss';

function AboutPage() {
  return (
    <main className="about">
      <h1 className="about__title">О проекте</h1>
    </main>
  );
}

export default AboutPage;
